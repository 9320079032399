.table thead th {
  border: none;
  /* padding: 0px 30px; */
  font-size: 14px;
  color: #949494;
  background-color: none;
  font-weight: 400;
  cursor: pointer;
}

.table tbody tr {
  /* margin-bottom: 10px; */
  background-color: white;
}

.table tbody th,
.table tbody td {
  border: none;
  vertical-align: middle;
  /* padding-left: 30px; */
  text-wrap: nowrap;
  padding-top:6px;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: 600;

}

.table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.table tbody .td_radiusLeft {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table tbody .td_radiusRight {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.select:before{
  background-color: white;
}

.table-responsive{
  overflow-x: unset !important;
}
.customize1:hover .customMenu1{
  display: block !important;
}

.cusDrag{
  width: 5%;
}
.cusDrag1{
  width: 5%;
}
.cusDrag2{
  width: 10%;
}
.cusDrag3{
  width: 15%;
}
.cusDrag4{
  width: 25%;
}
.cusDrag5{
  width: 10%;
}
.cusDrag6{
  width: 10%;
}
.cusDrag7{
  width: 25%;
}
.cusDrag8{
  width: 10%;
}

.tableUser thead th {
  border: none;
  padding: 0px 30px;
  font-size: 14px;
  color: #949494;
  background-color: none;
  font-weight: 400;
  cursor: pointer;
}

.tableUser tbody tr {
  margin-bottom: 10px;
  background-color: white;
}

.tableUser tbody th,
.tableUser tbody td {
  border: none;
  vertical-align: middle;
  padding-left: 30px;
  padding-top:20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 600;

}

.tableUser {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.tableUser tbody .td_radiusLeft {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tableUser tbody .td_radiusRight {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}