.passwordH3{
    font-size: 20px;
    font-weight: 700;
}
.carborderd1 {
    width: 70%;
    height: 380px;
    margin: auto;
}
@media (max-width: 767px) {
    .auth_body {
        width: 90%;
        margin: auto;
    }

    .button_body {
        width: auto;
    }

    .button_auth {
        width: 100% !important;
        margin: auto;
    }
}
.button_body {
    width: 364px;
}
.hideIcon{
    background-image: none !important;
}