.filter_button {
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 31px;
  width: 100%;
  background-color: white !important;
  margin-top: 6px;
  transition: all 0.2s;
}

.filter_button:hover {
  border: 1px solid #fc1921;
}

.filter_button:disabled {
    border: 1px solid #ced4da;
    color: #ced4da;
}
