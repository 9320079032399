.FormIntakeH3{
    font-size: 30px;
    font-weight: 700;
}
.titleStakeholder{
    font-size: 24px;
    font-weight: 700;
}
.infoLabel{
    font-size: 18px;
    font-weight: 700;
}
.infoLabel1{
    font-size: 14px;
    font-weight: 700;
}
.comprefBox {
    accent-color: #000000;
}

#formFileMultiple{
    border-style: dashed;
}

.size18700 {
    font-size: 18px;
    font-weight: 700;
}

.size14700 {
    font-size: 14px;
    font-weight: 700;
}

.editColor {
    color: #FC1921;
}

.comment_form {
    margin-bottom: 40px;
}

.comment_form a {
    cursor: pointer;
    transition: all 0.2s;
}

.comment_form a:hover {
    color: #851C1D;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer; 
  }
  .stakeholderLeft{
    margin-top: -270px;
  }
  .button_auth1 {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    border: none;
    border-radius: 30px;
    background-color: #FC1921;
    transition: all 0.2s ease-in-out;
}

.borderCard {
    padding-top: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    border: 1px solid #ced4da;
    border-radius: 4px 4px 4px 4px;
}

@media (min-width: 1200px) {
    .admin-comments {
        padding-left: 200px;
    }
}

.button_pdf {
    width: 100%;
    height: 53px;
    border: dashed 1px #ced4da;
    border-radius: 5px;
    background-color: white !important;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    color: black;
    text-decoration: none;
  }

  .button_pdf:hover {
    color: #FC1921;
  }
  
  textarea {
    resize: none;
    height: 200px !important;
  }

  .gy-40px{
    --bs-gutter-y: 40px
  }

  .row p {
    margin-bottom: 0;
  }

  .mb-40px {
    margin-bottom: 40px !important;
  }

  .csl-red-line {
    width: 100%;
    height: 1px;
    background-color: #FC1921;
    margin: 40px 0px;
  }
  .csl-red-line-new {
    width: 100%;
    height: 1px;
    background-color: #FC1921;
    margin: 10px 0px;
  }

  .isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }

  .upload-file-body {
    position: relative;
    border: 1px dashed rgb(212, 212, 212);
    border-radius: 10px;
    background-color: #FAFAFA;
    padding: 15px 30px;
    overflow: hidden;
    cursor: pointer;
  }
  a.design-upload {
    color: #8B8B8B;
  }
  
  a.design-upload:hover {
    color: black;
  }
  