.underline {
    width: 156px;
    cursor: pointer;
    background-color: white;
    border-top: 2px solid #FC1921;
    border-left: 1px solid #D7D7D7;
    border-right: 1px solid #D7D7D7;
}

.noneActive {
    width: 156px;
    cursor: pointer;
}

.bold_desc {
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
}

.addNew {
    height: 50px;
    width: 160px;
    background-color: white;
    border: 1px solid #D7D7D7;
    border-radius: 0.375rem;
    align-content: center;
    float: right;
    cursor: pointer;
}
.dropdownResetPassword{
    width: 180px !important;
    margin-left: -80px !important;
}