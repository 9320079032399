.loading-modal-backdrop {
   z-index: 1062 !important;
}

.loading-modal {
   z-index: 1063 !important;
   background: rgba(255, 255, 255, 0.8);
   backdrop-filter: blur(1px);
   -webkit-backdrop-filter: blur(3px);
}


.spinner {
   width: 40px;
   height: 40px;
   display: grid;
   border-radius: 50%;
   -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000000 41%);
   background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 0) center/3.2px 100%,
      linear-gradient(90deg, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.75) 0) center/100% 3.2px;
   background-repeat: no-repeat;
   animation: spinner-d3o0rx 1s infinite steps(12);
}

.spinner::before,
.spinner::after {
   content: "";
   grid-area: 1/1;
   border-radius: 50%;
   background: inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}

.spinner::after {
   opacity: 0.83;
   transform: rotate(60deg);
}

@keyframes spinner-d3o0rx {
   100% {
      transform: rotate(1turn);
   }
}
.modal-content {
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   color: var(--bs-modal-color);
   pointer-events: auto;
   background-color: transparent !important;
   background-clip: padding-box;
   border: none !important;
   border-radius: var(--bs-modal-border-radius);
   outline: 0;
}