@font-face {
    font-family: "Montserrat-Light";
    src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-ExtraBold";
    src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

* {
    font-family: "Montserrat";
}

h6 {
    font-family: 'Montserrat-Medium';
}

:root {
    --primary-red: #FC1921;
    --primary-black: #000000;
    --secondary-medical-green: #03b3be;
    --secondary-sky-blue: #88C0F0;
    --secondary-plasma-gold: #FFA814;
    --seoncdary-maroon: #851C1D;
    --soft-white: #FCFCFA;
    --off-white: #F1EFEA;
    --warm-gray: #E2DFDA;
}

body{
    background-color: #F5F5F5;
}

.size14520 {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

.size16520 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

hr {
    margin: 1rem 0;
    color: #ff0009;
    border: 0;
    border-top: 1px solid #ff0009;
    opacity: 1;
}