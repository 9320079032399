.headerLogin {
    font-size: 24px;
    font-weight: 700;
    padding-top: 40px;
}

.headerh3 {
    font-size: 24px;
    font-weight: 700;
}

.carborderd {
    width: 50%;
    height: auto;
    padding:10px 30px;
    border-left: none !important;
    border-right: none !important;
    border-color: #FC1921;
    border-width: 4px;
    border-radius: 4px 4px 4px 4px;
    border-bottom: none !important;
    border-style: outset;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.161);
    margin: auto;
}

@media (max-width: 767px) {
    .auth_body {
        width: 90%;
        margin: auto;
    }

    .button_body {
        width: auto;
    }

    .button_auth {
        width: 100% !important;
        margin: auto;
    }
}

.button_body {
    width: 364px;
}

.input-rounded {
    border: 1px solid rgba(219, 214, 214, 0.5);
    padding: 3px 14px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
}

.labelTextLogin {
    margin-bottom: 8px;
    margin-top: 18px;
    font-weight: 700;
    font-size: 14px;
}

.button_auth {
    width: 50%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    border: none;
    border-radius: 30px;
    background-color: #FC1921;
    transition: all 0.2s ease-in-out;
}